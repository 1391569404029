.contentContainer {
   padding-top: 61px;
   display: flex;
   gap: 150px;
   justify-content: center;
}

.title {
   font-family: "Jost", sans-serif;
   font-size: 56px;
   font-weight: 700;
   line-height: 54px;
   letter-spacing: 0em;
   text-align: left;
}
.descContainer {
    margin-top: 49px;
    display: flex;
    flex-direction: column;
gap: 13px;
}
.desc {
   font-family: "Jost", sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 23px;
   letter-spacing: 0em;
   text-align: left;
}
.socialLinkContainer {
    margin-top: 40px;
}
.textContainer {
   padding-top: 150px;
}
.map {
   height: 500px;
   width: 50%;
}

@media screen and (max-width: 1455px) {
    .contentContainer {
        gap: 50px;
    }
}


 @media screen and (max-width: 1200px) {
    .title {
        font-size: 46px;
    }
    .desc {
        font-size: 18px;
    }
    .descContainer {
        margin-top: 20px;
        gap: 8px;
    }
    .socialLinkContainer  {
        margin-top: 20px;
    }
 
 }
 @media screen and (max-width: 992px) {
   .contentContainer {
    flex-direction: column;
   }
   .textContainer {
    padding-top: 0px;
   }
   .map {
    width: 100%;
   }
 }

 @media screen and (max-width: 576px) {
    .title  {
        font-size: 36px;
    }
    .desc {
        font-size: 16px;
    }
    .descContainer {
        margin-top: 5px;
        gap: 1px;
    }
    .socialLinkContainer  {
        margin-top: 15px;
    }
 }
 