.section {
   height: 100%;
   width: 100%;
}
video {
   position: absolute;
   z-index: 1;
   opacity: 0.4;
   height: 100%;
   width: 100%;
   object-fit: cover;
}
.cover {
   position: absolute;
   z-index: 0;
   height: 100%;
   width: 100%;
   object-fit: cover;
   background: rgb(0, 0, 0);

}
.contentContainer {
   position: relative;
   height: 100%;
   color: #fff;
}
.container {
   height: 100%;
   display: flex;
   align-items: center;
   gap: 122px;
}
.textContainer {
   display: flex;
   flex-direction: column;
   gap: 90px;
}
.title {
   font-family: "Jost", sans-serif;
   font-size: 56px;
   font-weight: 700;
   line-height: 54px;
   letter-spacing: 0em;
   text-align: left;
}

.subtitle {
   font-family: "Jost", sans-serif;
   font-size: 27px;
   font-weight: 400;
   line-height: 35px;
   letter-spacing: 0em;
   text-align: left;
   max-width: 998px;
}
.playVideoBtn {
   cursor: pointer;
   position: relative;
   width: 200px;
   z-index: 100;
   height: 200px;
}
.playVideoBtn > img {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   transition: 0.7s;
   height: 100%;
}
.unactiveBtn {
   opacity: 0;
   pointer-events: none;
}
.activeBtn {
   opacity: 1;
   pointer-events: all;
}
.textContainer > * {
   position: relative;
   z-index: 100;
}

@media screen and (max-width: 1400px) {
   .playVideoBtn {
      width: 180px;
      height: 180px;
      margin-left: -60px;
   }
   .container {
      gap: 0px;
   }
   .subtitle {
      width: 90%;
   }
}
@media screen and (max-width: 1200px) {
   .textContainer {
      gap: 50px;
   }
   .subtitle {
      font-size: 24px;
      line-height: 32px;
   }
}
@media screen and (max-width: 992px) {
   .playVideoBtn {
      display: none;
   }
   .subtitle {
      width: 100%;
   }
}
@media screen and (max-width: 768px) {
   .title {
      font-size: 44px;
      line-height: 35px;
   }
   .subtitle {
      font-size: 22px;
      font-weight: 400;
      line-height: 26px;
   }
}
@media screen and (max-width: 576px) {
   .title {
      font-size: 36px;
      line-height: 35px;
   }
   .subtitle {
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
   }
}
