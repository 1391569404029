@import url(reset.css);

.container {
   width: 90%;
   margin: 0 auto;
   padding: 0 15px;
}

* {
   font-family: "Roboto", sans-serif;
}
body,
html {
   height: 100%;
   background: #fafafa;;
}

#root {
   min-height: 100%;
   display: flex;
   flex-direction: column;
   overflow: hidden;
   position: relative;

}

.main {
   flex: 1 1 auto;
}

.image-swiper-button-next-small , .image-swiper-button-prev-small {
   position: absolute !important;
   margin: 0 !important;
font-size: 30px !important;
}
.image-swiper-button-next-small {
   border-right: 3px solid #069FDB;
   border-top: 3px solid #069FDB;
   top: 50%;
   height: 20px;
   position: absolute;
   right: 20px;
   width: 20px;
   z-index: 100;
   transform: rotate(45deg);
}
.image-swiper-button-prev-small {
   border-right: 3px solid #069FDB;
   border-top: 3px solid #069FDB;
   height: 20px;
   position: absolute;
   width: 20px;
   z-index: 100;
   transform: rotate(-135deg);
   left: 20px;
   top: 50%;
}
@media screen and (max-width: 1400px) {
   .container {
      width: 1200px;
   }
}
@media screen and (max-width: 1200px) {
   .container {
      width: 960px;
   }
}
@media screen and (max-width: 992px) {
   .container {
      width: 720px;
   }
}
@media screen and (max-width: 768px) {
   .container {
      width: 540px;
   }
}
@media screen and (max-width: 576px) {
   .container {
      width: 100%;
   }
}
