.modal {
   position: absolute;
   z-index: 1000000;
   width: 600px;
   height: 300px;
   top: 400px;
   left: 50%;
   transform: translate(-50%, -50%);
   background: white;
   border-radius: 20px;
}
.cover {
   position: absolute;
   z-index: 1000000;
   height: 100vh;
   width: 100vw;
   opacity: 0;
   pointer-events: none;
   transition: 0.5s;
   background: rgba(0, 0, 0, 0.596);
}
.coverActive {
    opacity: 1;
    pointer-events: all;
}
.closeBtn {
   position: absolute;
   right: 10px;
   cursor: pointer;
   top: 10px;
}
.content {
   margin-top: 30px;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.title {
   font-family: "Jost", sans-serif;
   font-size: 30px;
   font-weight: 400;
   line-height: 66px;
   letter-spacing: 0em;
   text-align: left;
}
.input {
    width: 400px;
    padding: 10px;
    border-bottom: 1px solid black;
    text-align: center;
}
form {
    display: flex;
    flex-direction: column;
    gap: 35px;
    align-items: center;
}
.btn {
width: 200px;
padding: 15px;
border-radius: 10px;
border: 1px solid black;
transition: 0.7s;
color: black;
}
.btn:hover {
    background: black;
    color: #fff;
}