.section {
   padding-top: 70px;
   padding-bottom: 200px;
}
.content {
   display: flex;
   gap: 250px;
}

.left {
   display: flex;
   flex-direction: column;
   gap: 23px;
}

.right {
   display: flex;
   flex-direction: column;
   gap: 40px;
}
.leftTitle {
   font-family: "Jost", sans-serif;
   font-size: 32px;
   font-weight: 300;
   line-height: 38px;
   letter-spacing: 0em;
   text-align: left;
}
.leftSubtitle {
   font-family: "Jost", sans-serif;
   font-size: 16px;
   font-weight: 300;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
}
.leftBtn {
   width: 189px;
   height: 49px;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 20px;
   cursor: pointer;
   color: #069fdb;
   border: 1px solid #069fdb;
}
.rightTitle {
   font-family: "Jost", sans-serif;
   font-size: 45px;
   font-weight: 400;
   line-height: 52px;
   letter-spacing: 0em;
   text-align: left;
   color: #069fdb;
}
.rightSubtitle {
   font-family: "Jost", sans-serif;
   font-size: 28px;
   font-weight: 400;
   line-height: 37px;
   letter-spacing: 0em;
   text-align: left;
}

@media screen and (max-width: 1540px) {
   .rightTitle {
      font-size: 27px;
      line-height: 37px;
   }
   .rightSubtitle {
      font-size: 24px;
      line-height: 32px;
   }
   .content {
      gap: 200px;
   }
}

@media screen and (max-width: 1200px) {
   .rightTitle {
      font-size: 26px;
      line-height: 30px;
   }
   .rightSubtitle {
      font-size: 20px;
      line-height: 26px;
   }
   .content {
      gap: 100px;
   }
   .leftTitle {
      font-size: 26px;

      line-height: 30px;
   }
   .leftSubtitle {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
   }
}
@media screen and (max-width: 992px) {
   .section {
      padding-top: 0px;
   }
   .rightTitle , .rightSubtitle {
      display: none;
   }
   .rightTitle {
      font-size: 30px;
   }
   .rightSubtitle {
      font-size: 17px;
   }
   .content {
      flex-direction: column-reverse;
      gap: 40px;
   }
   .left {
      gap: 23px;
   }

   .right {
      gap: 20px;
   }
   .leftBtn {
      border-radius: 30px;
      margin: 0 auto;
   }
   .section {
      padding-bottom: 100px;
   }
}

@media screen and (max-width: 576px) {
   .section {
      padding-bottom: 60px;
   }
   .rightSubtitle {
      line-height: 21px;
   }
   .rightTitle {
      font-size: 24px;
      line-height: 26px;
   }
   .leftTitle {
      font-size: 21px;
      line-height: 24px;
   }
}

@media screen and (max-width: 400px) {
}
