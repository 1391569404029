.section {
   padding-bottom: 150px;
   position: relative;
}

.slideImg {
   object-fit: cover !important;
   width: 100%;
   border-right: 2px solid #414f5362;
   padding: 0px 40px;
   height: 400px;
   
}
.slide {
   cursor: pointer;
   display: flex;
   flex-direction: column;
}
.slideContent {
   display: flex;
   flex-direction: column;
   opacity: 0;
   gap: 10px;
   height: 100%;

   transition: 0.4s;
}
.descContainer {
   display: flex;
   margin-top: 46px;

}
.secondDesc {
   width: 50%;
   display: flex;
   flex-direction: column;
   gap: 30px;
}
.slideContentActive {
   height: auto;
width: 50%;
   position: relative;
   opacity: 1;
   transition: 0.4s;
}
.slideTitle {
   font-family: "Jost", sans-serif;
   font-size: 30px;
   width: 90%;
   font-weight: 400;
   line-height: 38px;
   letter-spacing: 0em;
}
.slideSubtitle {
   font-family: "Jost", sans-serif;
   font-size: 16px;
   width: 70%;
   font-weight: 400;
   line-height: 23px;
   letter-spacing: 0em;
}
.slideList {
   display: flex;
   flex-direction: column;
   gap: 10px;
}
.slideList > li  > span {
   font-family: "Jost", sans-serif;

}
.slideList > li {

   display: flex;
   gap: 10px;
   align-items: center;
   font-size: 16px;
   font-weight: 500;
   line-height: 16px;
   letter-spacing: 0em;
   text-align: left;
}
.extraTitle {
   font-family: "Jost", sans-serif;
   width: 90%;
color: #069fdb;
   font-size: 25px;
   font-weight: 600;
   line-height: 35px;
   letter-spacing: 0em;
   text-align: left;
}
.extraSubtitle {
   font-family: "Jost", sans-serif;
   width: 70%;

   font-size: 18px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
}
.btn {
   width: 158.34px;
   height: 45px;
   border-radius: 25px;
   background: #069fdb;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
   margin-top: 15px;
}
.sliderImgContainer > button {
   display: none;
}

@media screen and (max-width: 1400px) {
   .slideImg {
      height: 350px;
   }
   .slideTitle {
      font-size: 25px;
      width: 90%;
      line-height: 28px;
   }
   .slideSubtitle {
      width: 90%;
   }
   .extraTitle {
      font-size: 23px;
      line-height: 30px;
   }
}

@media screen and (max-width: 1200px) { 
   .extraTitle {
      font-size: 20px;
   line-height: 26px;

   }
   
   }
@media screen and (max-width: 992px) {
   .descContainer {
      flex-direction: column;
      gap: 40px;
   }
   .secondDesc , .slideContentActive  {
      width: 100%;
   }
   .slideImg {
      padding: 0 20px;
   }
}
@media screen and (max-width: 768px) {
   .extraSubtitle {
      width: 90%;
   }
   .slideContent {
      margin-top: 30px;
   }
   .slideTitle {
      font-size: 20px;
      line-height: 24px;
      width: 100%;
   }
   .slideList > li {
      font-size: 13px;
   }
   .extraTitle {
      font-size: 19px;
      line-height: 24px;
      width: 100%;
   }
   .slideSubtitle {
      font-size: 14px;
      line-height: 17px;
   }
   .extraSubtitle {
      font-size: 15px;
      line-height: 17px;
   }
}
@media screen and (max-width: 576px) {
   .descContainer {
      margin-top: 0px;
   }
   .secondDesc , .slideContentActive  {
      padding: 0 15px;
   }
   .extraTitle {
      font-size: 17px;
      line-height: 22px;
   }
   .sliderImgContainer  {
      position: relative;
   }
   .sliderImgContainer > button {
      display: block;
   }
.containerSwiper {
   padding: 0px;
}
   .slideImg {

      padding: 0px;
      border: none;
   }
}
