.section {
   padding-top: 190px;
padding-bottom: 90px;
position: relative;
}
.section div:first-child > * {
   position: relative;
   z-index: 100;
   color: #fff;
}
.slider {
   display: flex;
}
.cover {
   position: absolute;
   top: 0;
 height: 100%;
   width: 100%;
   background: #223035;
   opacity: 0.6;
}
.container {
   display: flex;
   flex-direction: column;
justify-content: space-between;
gap: 100px;
}
.mainBg {
   position: absolute;
   top: 0;
height: 100%;
   width: 100%;
object-fit: cover;
   opacity: 0.9;
}
.slide p {
   font-family: "Jost", sans-serif;

   font-size: 64px !important;
   font-weight: 400 !important;
   line-height: 62px !important;
   letter-spacing: 0em !important;
   text-align: right !important;
}
.slide img {
   margin-top: 52px;
   width: 53px;
   height: 53px;
   cursor: pointer;
}
.slide {
   display: flex !important;
   flex-direction: row-reverse !important;
   gap: 20px !important;
   align-items: center !important;
}

.content img {
   pointer-events: none;
}
.scrollContainer {
   position: absolute;
   top: 66%;
   left: 50%;
   font-family: "Roboto";
   font-size: 14px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0em;
   text-align: center;
   cursor: pointer;
   transform: translate(-50%, -50%);
}
.scrollContainer img {
   width: 25px;
   height: 45px;
}
.right {
   display: none;
}
.changeLang img:first-child {
   object-fit: cover;
   width: 25px;
   height: 25px;
}
.changeLang {
   display: flex;
   align-items: center;
   gap: 10px;
   cursor: pointer;
   position: relative;
   width: 180px;
}
.changeLangList {
   width: 100%;
   height: 200%;
   top: 0;
   position: absolute;
   display: flex;
   flex-direction: column;
   justify-content: center;
pointer-events: none;
   gap: 5px;
   opacity: 0;
   font-weight: 600;
   transition: 0.6s;
}
.changeLangListActive {
   top: 100%;
   opacity: 1;
   pointer-events: all;
}

.rightTitle {
   font-family: "Jost", sans-serif;
   font-size: 35px;
   font-weight: 400;
   line-height: 40px;
   letter-spacing: 0em;
   text-align: left;
   color: #069FDB;
   font-weight: 600;
}
.rightSubtitle {
   font-family: "Jost", sans-serif;
   font-size: 28px;
   font-weight: 400;
   line-height: 37px;
   letter-spacing: 0em;
   text-align: left;
}

@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1200px) {
   .scrollContainer {
      display: none;
   }
   .container {
      gap: 100px;
      justify-content: flex-start;
   }
   .slide p {
      font-size: 60px !important;
   }
}
@media screen and (max-width: 992px) {
   .changeLang {
      display: none;
   }
.right {
   display: flex;
   flex-direction: column;
   gap: 15px;
}
   .slide p {
      font-family: "Jost", sans-serif !important;
      font-size: 50px !important;
      font-weight: 400 !important;
      line-height: 62px !important;
      letter-spacing: 0em !important;
      text-align: right !important;
      display: flex !important;
      flex-direction: row-reverse !important;
   }
   .container {
      gap: 40px;
   }
   .slide img {
      width: 40px;
      height: 40px;
   }
   .rightTitle {
      font-family: "Jost", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      
   }
   .rightSubtitle {
      font-family: "Jost", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      
   }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 576px) {
   .container {
      gap: 70px;
   }
   .rightTitle {
      font-family: "Jost", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      
   }
   .rightSubtitle {
      font-family: "Jost", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      
   }
   .slide p {
      font-family: "Jost", sans-serif !important;

      font-size: 30px !important;
      font-weight: 400 !important;
      line-height: 33px !important;
      letter-spacing: 0em !important;
      text-align: right !important;
   }
   .slide img {
      width: 45px;
      height: 45px;
      margin-top: 0px;
      display: none;
   }
}
@media screen and (max-width: 376px) {
   .slide p {
      font-family: "Jost", sans-serif !important;

      font-size: 27px !important;
      font-weight: 400 !important;
      line-height: 37px !important;
      letter-spacing: 0em !important;
      text-align: right !important;
   }
}
