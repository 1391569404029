.section {
    /* padding: 288px 470px 420px 470px; */
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg {
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
object-fit: cover;
}
.cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.671);
}
.content {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding:0px 15px;
}
.content p {
    font-family: "Jost", sans-serif;
color: #fff;
font-size: 56px;
font-weight: 400;
line-height: 81px;
letter-spacing: 0em;
text-align: center;

}
.btn {
    width: 158.34px;
    height: 45px;
    border-radius: 25px;
    background: #069fdb;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


@media screen and (max-width: 1400px) {
    .content p {
        font-size: 46px;
        line-height: 65px;
    }
 }
 @media screen and (max-width: 1200px) {

 }
 @media screen and (max-width: 992px) {
    .content p {
        font-size: 36px;
        line-height: 55px;
    }
 }
 @media screen and (max-width: 768px) {

 }
 @media screen and (max-width: 576px) {
    .content p  >br {
        display: none;
    }
    .content p {
        font-size: 28px;
        line-height: 37px;
    }
 }
 