.sidebar {
   position: absolute;
   height: 100%;
   top: 0;
   width: 100%;
   right: -100%;
   display: flex;
   justify-content: center;
   padding-top: 80px;
   background: rgb(177, 177, 177);
   z-index: 100000;
   transition: 1s;
}
.menu {
   display: flex;
   flex-direction: column;
   gap: 20px;
   width: 80%;
   align-items: flex-end;
}
.menu > * {
   font-size: 20px;
   font-family: "Jost", sans-serif;

   font-size: 20px;
   font-weight: 400;
   line-height: 29px;
   letter-spacing: 0em;
   text-align: right;

   color: black;
}
.closeBtn {
   position: absolute;
   right: 15px;
   top: 15px;
}
.sidebarActive {
   right: 0px;
}
