.header {
   padding-top: 31px;
   z-index: 10000;
}
.headerAbsolute {
   position: absolute;
   width: 100%;
}
.logoContainer {
   display: flex;
   align-items: flex-end;
   margin-right: 105px;
   cursor: pointer;
}

.logoContainer p {
   margin-bottom: -2px;
   font-size: 25px;
   font-weight: 600;
}
.headerContainer {
   padding: 20px 0px;
   border-top: 1px solid #379bd5;
   border-bottom: 1px solid #379bd5;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.headerMenu {
   display: flex;
   align-items: center;
   gap: 120px;
}
.headerMenu > * {
   cursor: pointer;
   font-weight: 500;
   font-size: 17px;
   color: black;
}
.btn {
   margin-left: 65px;
   padding: 13px 25px;
   font-size: 14px;
   display: flex;
   align-items: center;
   gap: 10px;
   color: #fff;
   font-family: "DM Sans", sans-serif;
   border-radius: 30px;
   background: #069fdb;
}
.btn img {
   margin-top: -3px;
}
.burgerMenu {
   display: none;
}
.changeLang {
   display: none;
}
.mobileMenuContainer {
   display: none;
}
.activeLink {
   color: red !important;
}

@media screen and (max-width: 1500px) { 

   .headerMenu {
      gap: 70px;
   }
}
@media screen and (max-width: 1200px) {
   .logoContainer img {
      width: 84px;
      height: 23px;
   }
   
.logoContainer p  {
   font-size: 16px;
}
   .headerMenu {
      gap: 50px;
   }
   .headerMenu > * {
      font-size: 14px;
   }
   .logoContainer {
      margin-right: 55px;
   }
   .btn {
      margin-left: 45px;
      padding: 10px 15px;
      font-size: 13px;
   }
}
@media screen and (max-width: 992px) {
   .headerMenu {
      display: none;
   }
   .btn {
      display: none;
   }
   .burgerMenu {
      display: block;
   }
   .headerContainer {
      justify-content: space-between;
   }
   .mobileMenuContainer {
      display: flex;
      gap: 20px;
   }
   .changeLang {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 5px;
      width: 40px;
      position: relative;
   }
   .arrow {
      width: 11px;
      height: 5px;
   }
   .ulList {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transition: 0.5s;
      opacity: 0;
      pointer-events: none;
   }
   .ulList li {
      font-weight: 500;
   }
   .ulListActive {
      top: 100%;
      opacity: 1;
      pointer-events: all;
   }
}

@media screen and (max-width: 576px) {
   .headerContainer {
      border: none;
      padding: 0 15px;
   }
}
