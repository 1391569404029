.videoContainer {
   height: 100vh;
   position: relative;
   top: 0;
}
.textContainer {
   padding-top: 87px;
}
.title {
   font-family: "Jost", sans-serif;
   text-align: left;
   font-size: 23px;
   font-weight: 400;
   line-height: 30px;
   letter-spacing: 0em;
}

.cardContainer {
   margin-top: 90px;
   display: flex;
   justify-content: space-between;
   gap: 110px;
}
.card {
   display: flex;
   flex-direction: column;
   gap: 49px;
}
.card p {
   font-family: "Jost", sans-serif;
   font-size: 20px;
   font-weight: 400;
   line-height: 23px;
   letter-spacing: 0em;
   text-align: left;
}
.card img {
   width: 220px;
   height: 90px;
   object-position: center center;
}
.card:last-child img {
   width: 222px;
}


@media screen and (max-width: 1200px) {
   .cardContainer {
      flex-wrap: wrap;
      justify-content: center;
   }
   .card {
      width: 44%;
   }
}
@media screen and (max-width: 992px) {
    .card {
        width: 100%;
    
    }
}
@media screen and (max-width: 768px) {
   .title {
      font-size: 22px;
      font-weight: 400;
      line-height: 26px;
   }
   .cardContainer {
    gap: 60px;
   }
   .textContainer {
      padding-top: 40px;
   }
   .card {
    gap: 25px;
   }
   .card p {
    font-size: 18px;
   }
}
@media screen and (max-width: 576px) {
   .title {
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
   }
   .card img  {
      width: auto;
   }
   .card p  {
    font-size: 16px;
    line-height: 19px;
   }
}
