.section {
   padding-top: 100px;
   padding-bottom: 40px;
}
.cardContainer {
   display: flex;
   justify-content: space-between;
}
.logoContainer img {
   width: 83px;
   height: 23px;
   object-fit: cover;
}
.logoContainer p {
   margin-top: 7px;
   font-weight: 500;
}
.logoContainer {
   display: flex;
   align-items: center;
   gap: 3px;
}
.form {
   display: flex;
   flex-direction: column;
   gap: 24px;
}
.form p {
   font-size: 15px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
}
.inputContainer {
   margin-top: 10px;
   display: flex;
   padding-bottom: 10px;
   border-bottom: 2px solid #96a2a279;
}
.inputContainer button {
   width: 154px;
   height: 40px;
   border-radius: 25px;
   background: #069fdb;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 5px;
}
.card {
   display: flex;
   flex-direction: column;
   gap: 21px;
}
.cardTitle {
   font-family: "Jost", sans-serif;

   font-size: 20px;
   font-weight: 600;
   line-height: 30px;
   letter-spacing: 0em;
   text-align: left;
}
.cardDesc {
   font-family: "Jost", sans-serif;

   font-size: 15px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
}
.descContainer {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

@media screen and (max-width: 992px) {
   .card {
      display: none;
   }
   .inputContainer {
      display: none;
   }
   .form {
      align-items: center;
   }
   .cardContainer {
      justify-content: center;
   }
   .logoContainer img {
      width: auto;
      height: auto;
   }
   .logoContainer p {
      font-size: 24px;
      margin-top: 20px;
      font-weight: 600;
   }
   .desc {
   font-family: "Jost", sans-serif;

      font-size: 23px !important;
   }
}
@media screen and (max-width: 768px) {
   .desc {
      font-size: 20px !important;
      text-align: center !important;
   }
   .logoContainer img {
      width: 83px;
      height:23px;
   }
   .section {
      padding-top: 60px;
   }
}
@media screen and (max-width: 576px) {
.section {
   padding-top: 40px;
}
   .desc {
      font-size: 15px  !important;
   }
   .logoContainer p  {
      font-size: 15px;
   margin-top: 7px;

   }
}
