.section {
   padding-top: 70px;
   padding-bottom: 50px;
}

.title {
   text-align: center;
   font-family: "Jost", sans-serif;
   font-size: 42px;
   font-weight: 400;
   line-height: 52px;
   letter-spacing: 0em;
   text-align: center;
}
.korea {
   margin-top: 40px;
}
.img {
   width: 316px;
   height: 204px;
   margin-left: 20px;
}
.imgRussia {
    width: 204px;
    height: 126px;
    margin-left: 100px;
}
.marquee {
   margin-top: 40px;
}
.russiaContainer {
   display: flex;
   margin-top: 40px;
   align-items: center;
   justify-content: center;
   gap: 20px;
}
.russiaContainer img {
   width: 31px;
   height: 15px;
   cursor: pointer;
   transform: rotate(180deg);
   transition: 0.4s;
   
}
.russiaBlock {
   position: relative;
}
.marqueeRussia {
   opacity: 0;
   height: 0 !important;
   pointer-events: none;
   transition: 0.4s;
}
.marqueeRussiaActive {
   height: 200px !important;

   opacity: 1;
}
.activeArrow {
transform: rotate(0deg) !important;
}



@media screen and (max-width: 1400px) {

 }
 @media screen and (max-width: 1200px) {

 }
 @media screen and (max-width: 992px) {
    .title {
        font-size: 37px;
        }
 }
 @media screen and (max-width: 768px) {
.title {
font-size: 32px;
}
 }
 @media screen and (max-width: 576px) {

 }
 