

.section {
   background: #fafafa;
   padding-top: 126px;
   padding-bottom: 100px;
 
}
.title {
   font-family: "Jost", sans-serif;
   font-size: 56px;
   font-weight: 400;
   line-height: 66px;
   letter-spacing: 0em;
   text-align: left;
}
.cardContainer {
   margin-top: 62px;
   display: flex;
   justify-content: center;
}

.cardTitle, .cardTitleMobile > span {
   font-family: "Jost", sans-serif;
   font-size: 25px;
   font-weight: 500;
   line-height: 36px;
   letter-spacing: 0em;
   text-align: left;
   color: #afc1c5;
}
.subtitle,
 .subtitleMobile > span {
   font-family: "Jost", sans-serif;
   color: #afc1c5;
   font-size: 15px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
}
.cardText {
   display: flex;
   flex-direction: column;
   gap: 17px;
}
.btn {
   width: 158.34px;
   height: 45px;
   border-radius: 25px;
   background: #069fdb;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
}
.cardContent {
   display: flex;
   padding: 20px;
   width: 0;
   opacity: 0;
   gap: 40px;

   transition: 1s;
}

.card {
   width: 15%;
   display: flex;
   cursor: pointer;
   transition: 1s;
   border-right: 2px solid #069fdb77;
   overflow: hidden;
}
.cardActive {
   width: calc(100% - 15% * 3);
}
.cardText {
   width: 45%;
   opacity: 0;
   transition: 1s;
}
.subtitle {
   white-space: nowrap;
}
.cardTitle {
   white-space: nowrap;
}
.mainImg {
   width: 300px;
   height: 400px;
   opacity: 0;
   transition: 0.5s;
   object-fit: cover;
}
.cardActive .mainImg {
   opacity: 1;


}

.cardActive .cardContent {
   width: 100%;
   opacity: 1;
   margin-right: -100px;
}
.cardActive .cardText {
opacity: 1;
}
.cardHeader {
   display: flex;
   flex-direction: column-reverse;
   position: relative;
   align-items: center;
   padding: 30px 0px;
   gap: 40px;
   justify-content: space-between;
}
.number {
   font-family: "Jost", sans-serif;
   font-size: 200px;
   font-weight: 500;
   letter-spacing: 0em;
   text-align: left;
   color: #069fdb80;
   writing-mode: vertical-rl;
   transform: rotate(-180deg);
   position: relative;
   right: -10px;
}
.headerTitle {
   font-family: "Jost", sans-serif;
   font-size: 32px;
   font-weight: 400;
   line-height: 52px;
   letter-spacing: 0em;
   text-align: left;
   color: #069fdb80;
   writing-mode: vertical-lr;
   transform: rotate(-180deg);
}
.cardActive .arrow {
   transform: rotate(180deg);
}
.arrow {
   transition: 0.7s;
}

.mobileVersion {
   /* display: none; */
   opacity: 0;
   pointer-events: none;
   position: absolute;
}

@media screen and (max-width: 1750px) {
   .number {
      font-size: 90px;
   }
   .cardActive .cardContent {
      margin-right: -100px;
   }
   .mainImg {
      width: 240px;
   }
}

@media screen and (max-width: 1400px) {
   .cardActive .cardContent {
      margin-right: -130px;
   } 
   .arrow {
      width: 32px;
      height: 32px;
   }

   .mainImg {
      width: 200px;
      height: 300px;
   }
}
@media screen and (max-width: 1200px) {
   .cardContent {
      flex-direction: column;
   }
   .mainImg {
      width: 300px;
      height: 350px;
      object-fit: cover;
   }
   .section {
      padding-top: 80px;
      padding-bottom: 80px;
   }
}
@media screen and (max-width: 992px) {
   .cardContainer {
      display: none;
   }
   .mobileVersion {
      /* display: block; */
      opacity: 1;
      pointer-events: all;
      position: relative;
      margin-top: 40px;
   }
   .mobileTextContainer {
      display: flex ;
      flex-direction: column ;
      gap: 17px;
   }
   .mobileContainer {
      display: flex;
      justify-content: space-between;
   }
   .mobileContent {
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 60px;
   }
   .mobileImgContainer {
      position: relative;
      height: 600px;
   }
   .mobileMainImg {
      height: 100%;
      opacity: 0;
   
      transition: 1s;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
   }
   .mobileImgActive {
      opacity: 1;
   }
   .numberMobile {
      font-family: "Jost", sans-serif;
      font-size: 150px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
      color: #069fdb2f;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      display: flex;
      transition: 1s;
      align-items: center;
   }
   .numberMobileActive {
      color: #069fdb80;

   }

   .arrowMobile {
      margin-left: 80px;
      width: 32px;
      height: 32px;
      transform: rotate(180deg);
      transition: 1s;
   }
   .numberMobileActive .arrowMobile {
      transform: rotate(0deg);

   }
   .mobileNumberContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
   }
   .cardTitle {
      position: relative;
   }
   .cardTitleMobile {
      position: relative;
   }
   .subtitleMobile {
      position: relative;
   }
   .cardTitleMobile > span {
position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      transition: 1s;
   }
   .subtitle {
      position: relative;
   }
   .subtitleMobile > span {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 1s;
   }
   .textMobileActive {
      opacity: 1 !important;
      position: relative !important;
   }
   .titleMobileActive {
      opacity: 1 !important;
      position: relative !important;
   }
   .section {
      padding-top: 60px;
      padding-bottom: 60px;
   }
}
@media screen and (max-width: 768px) {

   .cardTitle > span > br , .subtitle > span >  br {
      display: none;
   }
   .br {
      display: block !important;
   }
   .section {
      padding-top: 40px;
      padding-bottom: 60px;
   }
   .numberMobile {
      font-size: 130px;
   }
   .mobileImgContainer  {
      height: 400px;
   }
   .mobileMainImg {
width: 100%;
      height: 400px;
   }
   .arrowMobile {
      width: 22px;
      height: 22px;
   }
 
}
@media screen and (max-width: 576px) {
   .mobileTextContainer {
      padding-left: 15px;
   }

.mobileContainer {
   overflow: hidden;
}
.numberMobile {
   font-size: 170px;
}
.mobileNumberContainer {
   overflow: hidden;
   margin-right: -40px;
}
.title {
   padding-left: 15px;
   font-size: 42px;
}

   .container {
      padding: 0px;
   }
}
@media screen and (max-width: 400px) { 
   .mobileMainImg {
      width: 100%;
   }
   .numberMobile {
      font-size: 130px;
      margin-right: 0px;
   }
}