.section {
   padding-bottom: 100px;
}
.cardContainer {
   display: flex;
   justify-content: space-between;
}
.card {
   display: flex;
   flex-direction: column;
   gap: 35px;
}
.card img {
   width: 75px;
   height: 75px;
}
.title {
   font-family: "Jost", sans-serif;
   font-size: 55px;
   font-weight: 400;
   line-height: 70px;
   letter-spacing: 0em;
   text-align: left;
}

@media screen and (max-width: 1200px) {
   .card {
      gap: 20px;
   }
   .title {
      font-size: 50px;
   }
}
@media screen and (max-width: 992px) {
   .card {
      width: 45%;
      gap: 10px;
      align-items: center;
   }
   
   .cardContainer {
      flex-wrap: wrap;
      gap: 40px;
   }
}
@media screen and (max-width: 768px) {
    .title {
        font-size: 35px;
    }
}
@media screen and (max-width: 576px) {
    .card {
        width: 100%;
    }
    .title {
        font-size: 40px;
    }
}
